import React from 'react'
import Obfuscate from 'react-obfuscate'
import { Link } from 'gatsby'
import logo from '../assets/images/logo.png'

export default function Cta () {
  return (
    <section id="cta">
      <div className="row container">
        <div className='col-4 col-12-narrower'>
          <p><img src={logo} alt="AD Topografia - Logo con drone" /></p>
          <h3>AD Topografia</h3>
          <p>Via Palestro 2 - 23900 Lecco</p>
        </div>
        <div className='col-4 col-12-narrower'>
          <h4>Mappa del sito</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/lavori-con-drone">Lavori con drone</Link></li>
            <li><Link to="/lavori-topografici-e-catastali">Lavori topografici e catastali</Link></li>
            <li><Link to="/privacy-policy">Privacy policy</Link></li>
          </ul>
        </div>
        <div className='col-4 col-12-narrower'>
          <h4>Contatti</h4>
          <ul>
            <li>
              <h5>Geom. Adamoli Daniele</h5>
              <p>328.1319875</p>
              <p><Obfuscate email={process.env.MAIL1} /></p>
              <p>P.IVA: 03052620139</p>
            </li>
            <li>
              <h5>Geom. Gottifredi Andrea</h5>
              <p>342.0044187</p>
              <p><Obfuscate email={process.env.MAIL2} /></p>
            </li>

          </ul>
        </div>
      </div>
    </section>
  )
}
