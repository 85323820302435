import React from 'react'
import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'
import logo from '../assets/images/logo-strip-transparent.png'

import { Link } from 'gatsby'

export default function Header ({ className, menuItems, useGatsbyLink }) {
  return (
    /* <!-- Header --> */
    <header id='header' className={className}>
      <h1>
        <Link to='/'><img src={logo} alt="Logo AD Topografia" /></Link>
      </h1>
      <nav id='nav'>
        <Scrollspy items={ ['banner', 'team', 'services', 'contacts'] } currentClassName="active">
          {menuItems.map(item =>
            <Scroll type="id" element={item.id} key={item.id}>
              <li>
                {useGatsbyLink
                  ? <Link to={item.href || '#'} className={item.className}>{item.label}</Link>
                  : <a href={item.href || '#'} className={item.className}>{item.label}</a>}
              </li>
            </Scroll>
          )}
        </Scrollspy>

      </nav>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  useGatsbyLink: PropTypes.bool
}

Header.defaultProps = {
  useGatsbyLink: true
}
