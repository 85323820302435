export default [
  {
    id: 'banner',
    label: 'Home',
    href: '/#banner'
  },
  {
    id: 'team',
    label: 'Chi siamo',
    href: '/#team'
  },
  {
    id: 'services',
    label: 'Servizi',
    href: '/#services'
  },
  {
    id: 'equipment',
    label: 'Strumentazione',
    href: '/#equipment'
  },
  {
    id: 'contacts',
    label: 'Contatti',
    href: '/#contacts'
  }
]
