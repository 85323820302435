import React from 'react'
import PropTypes from 'prop-types'

const filterOut = (props, propsToFilter) => {
  const rest = {}
  Object.keys(props).forEach((key, index) => {
    if (!(propsToFilter.includes(key))) {
      rest[key] = props[key]
    }
  })
  return rest
}

export default function Card (props) {
  const { img, children, bottomImage } = props
  const validProps = filterOut(props, ['img', 'bottomImage'])
  return (
    <div {...validProps}>
      <section className='box special'>
        {!bottomImage && img && <span className='image featured'>
          <img src={img} alt='' />
        </span>
        }
        {children}
        {bottomImage && img && <span className='image featured'>
          <img src={img} alt='' />
        </span>
        }
      </section>
    </div>
  )
}

Card.propTypes = {
  bottomImage: PropTypes.bool,
  img: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
}
