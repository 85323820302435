import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Swipe from 'react-easy-swipe'
import Scrollspy from 'react-scrollspy'
import Backdrop from './Backdrop'
import Scroll from './Scroll'

const MobileMenu = ({
  showBackdrop,
  menuItems,
  toggleDrawer,
  useGatsbyLink
}) => {
  let backdrop

  if (showBackdrop) {
    backdrop = <Backdrop click={toggleDrawer} />
  }
  return (
    <Swipe onSwipeLeft={toggleDrawer}>
      <div id="navButton">
        <a className="toggle" onClick={toggleDrawer}></a>
      </div>
      {backdrop}
      {useGatsbyLink ? (
        <div id="navPanel">
          {menuItems.map(item => (
            <Link to={item.href || '#'} key={item.id} className="link depth-0">
              {item.label}
            </Link>
          ))}
        </div>
      ) : (
        <div id="navPanel" onClick={toggleDrawer}>
          <Scrollspy
            componentTag="nav"
            items={['banner', 'team', 'services', 'contacts']}
            currentClassName="active"
            offset={300}
          >
            {menuItems.map(item => (
              <Scroll type="id" element={item.id} key={item.id}>
                <a href={item.href || '#'} className="link depth-0">
                  {item.label}
                </a>
              </Scroll>
            ))}
          </Scrollspy>
        </div>
      )}
    </Swipe>
  )
}

MobileMenu.propTypes = {
  showBackdrop: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  toggleDrawer: PropTypes.func,
  useGatsbyLink: PropTypes.bool
}

MobileMenu.defaultProps = {
  showBackdrop: false,
  useGatsbyLink: true
}

export default MobileMenu
