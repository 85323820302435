import React from 'react'

export default function Footer () {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>www.adtopografia.it © {new Date().getFullYear()} - All rights reserved.</li>
      </ul>
    </footer>
  )
}
