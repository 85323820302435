import { MDXProvider } from '@mdx-js/react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import '../assets/sass/main.scss'
import siteSections from '../menuItemsMdx'
import Card from './Card'
import Container from './Container'
import Cta from './Cta'
import Footer from './Footer'
import Header from './Header'
import MobileMenu from './MobileMenu'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      isPreloaded: true
    }
    this.onDrawerClose = this.onDrawerClose.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false })
    }, 100)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  onDrawerClose () {
    this.setState({ open: false })
  }

  toggleDrawer () {
    this.setState({ open: !this.state.open })
  }

  render () {
    const { children } = this.props
    const { isPreloaded } = this.state
    const { title, header, subheader, description, keywords } = this.props.pageContext.frontmatter
    return (
      <MDXProvider components={Container}>
        <StaticQuery
          query={graphql`
          query SiteTitleQueryMdx {
            mdx {
              frontmatter {
                title
                header
                subheader
              }
            }
          }
        `}
          render={data => (
            <>
              <Helmet
                title={title}
                meta={[
                  { name: 'description', content: description },
                  { name: 'keywords', content: keywords }
                ]}
              >
                <html lang="en" />
                <body className={`${this.state.open && 'navPanel-visible'}`} />
              </Helmet>
              <div id="page-wrapper" className={isPreloaded ? 'page-wrapper is-preload' : 'page-wrapper'}>
                <Header menuItems={siteSections} useGatsbyLink={true}/>
                <section id="main" className="container">
                  <header>
                    <h2>{header}</h2>
                    <p>{subheader}</p>
                  </header>
                  <Card className="mdx-container">
                    {children}
                  </Card>
                </section>
                <Cta />
                <Footer />
              </div>
              <MobileMenu
                showBackdrop={this.state.open}
                menuItems={siteSections}
                toggleDrawer={this.toggleDrawer}
                useGatsbyLink={true}
              />

            </>
          )}
        />
      </MDXProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Layout
