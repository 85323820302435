import React from 'react'
import PropTypes from 'prop-types'

const style = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'rgba(0,0,0,0.3)',
  zIndex: 100
}

const Backdrop = props => (
  <div className="backdrop" style={style} onClick={props.click} />
)

Backdrop.propTypes = {
  click: PropTypes.func
}

export default Backdrop
